import React from "react";
import PerformanceMeter from "../../Images/performanceMeter.svg";
import Battery from "../../Images/battery.svg";
import Portable from "../../Images/portable.svg";
import Right from "../../Images/right.svg";
import PlatformImage from "../../Images/c20 SE.svg";

function PaymentPlatform() {
  return (
    <>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center py-[60px] sm:py-[80px] lg:py-[100px] px-4 sm:px-6 lg:px-8">
          <p className="text-[24px] sm:text-[32px] lg:text-[42px] xl:text-[45px] max-w-[826px] text-center font-semibold leading-none">
            Power your business with the ultimate payments platform.
          </p>
          <p className="text-sm sm:text-base lg:text-lg xl:text-xl max-w-[800px] xl:text-[17px] text-center mt-[22px]">
            The AndPos N82 is a smart POS terminal with secure and stable
            performance. It runs the Android 10 operating system and has a
            quad-core processor, providing a premium experience with a large
            battery. Its slim and light design makes it portable.
          </p>
        </div>
        <div className="container mx-auto lg:px-10 mb-20">
          <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-start">
            <div className="grid grid-cols-2 gap-[40px] sm:gap-[60px] lg:gap-[60px] xl:gap-[100px] mb-8 lg:mb-0">
              <div className="text-center">
                <div className="border-4 rounded-full w-[100px] h-[100px] sm:w-[120px] sm:h-[120px] lg:w-[140px] lg:h-[140px] xl:w-[160px] xl:h-[160px] flex justify-center items-center mx-auto">
                  <img
                    src={PerformanceMeter}
                    className="w-[60px] h-[60px] sm:w-[80px] sm:h-[80px] lg:w-[100px] lg:h-[100px] xl:w-[120px] xl:h-[120px]"
                  />
                </div>
                <p className="text-sm sm:text-base lg:text-lg xl:text-xl mt-[12px] sm:mt-[16px] lg:mt-[22px] xl:mt-[28px] text-[#363434] font-medium">
                  Performance
                </p>
              </div>

              <div className="text-center">
                <div className="border-4 rounded-full w-[100px] h-[100px] sm:w-[120px] sm:h-[120px] lg:w-[140px] lg:h-[140px] xl:w-[160px] xl:h-[160px] flex justify-center items-center mx-auto">
                  <img
                    src={Portable}
                    className="w-[60px] h-[60px] sm:w-[80px] sm:h-[80px] lg:w-[100px] lg:h-[100px] xl:w-[120px] xl:h-[120px]"
                  />
                </div>
                <p className="text-sm sm:text-base lg:text-lg xl:text-xl mt-[12px] sm:mt-[16px] lg:mt-[22px] xl:mt-[28px] text-[#363434] font-medium">
                  Portable
                </p>
              </div>

              <div className="text-center">
                <div className="border-4 rounded-full w-[100px] h-[100px] sm:w-[120px] sm:h-[120px] lg:w-[140px] lg:h-[140px] xl:w-[160px] xl:h-[160px] flex justify-center items-center mx-auto">
                  <img
                    src={Battery}
                    className="w-[60px] h-[60px] sm:w-[80px] sm:h-[80px] lg:w-[100px] lg:h-[100px] xl:w-[120px] xl:h-[120px]"
                  />
                </div>
                <p className="text-sm sm:text-base lg:text-lg xl:text-xl mt-[12px] sm:mt-[16px] lg:mt-[22px] xl:mt-[28px] text-[#363434] font-medium">
                  Power-Efficient
                </p>
              </div>

              <div className="text-center">
                <div className="border-4 rounded-full w-[100px] h-[100px] sm:w-[120px] sm:h-[120px] lg:w-[140px] lg:h-[140px] xl:w-[160px] xl:h-[160px] flex justify-center items-center mx-auto">
                  <img
                    src={Right}
                    className="w-[60px] h-[60px] sm:w-[80px] sm:h-[80px] lg:w-[100px] lg:h-[100px] xl:w-[120px] xl:h-[120px]"
                  />
                </div>
                <p className="text-sm sm:text-base lg:text-lg xl:text-xl mt-[12px] sm:mt-[16px] lg:mt-[22px] xl:mt-[28px] text-[#363434] font-medium">
                  Sm Day Settlement
                </p>
              </div>
            </div>

            <img
              src={PlatformImage}
              className="w-[180px] h-auto sm:w-[250px] lg:w-[550px] xl:w-[35%] xl:h-auto max-w-full"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentPlatform;
