import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Contact({open , isOpen}) {
    const [formValues, setFormValues] = useState({
        merchant_name: '',
        email: '',
        mobile_number: '',
        city: '',
        product: '',
        quantity: '',
        message: ''
    });

    const resetForm = () => {
        setFormValues({
            merchant_name: '',
            email: '',
            mobile_number: '',
            city: '',
            product: '',
            quantity: '',
            message: ''
        });
    };

    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };
  
    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'merchant_name':
                if (!value) error = 'Merchant Name is required';
                break;
            case 'email':
                if (!value) error = 'Email is required';
                else if (!/\S+@\S+\.\S+/.test(value)) error = 'Email is invalid';
                break;
            case 'mobile_number':
                if (!value) error = 'Mobile Number is required';
                else if (!/^\d{10}$/.test(value)) error = 'Mobile Number must be 10 digits';
                break;
            case 'city':
                if (!value) error = 'City is required';
                break;
            case 'product':
                if (!value) error = 'Product is required';
                break;
            case 'quantity':
                if (!value) error = 'Quantity is required';
                break;
            default:
                break;
        }
        return error;
    };

    const validate = () => {
        const newErrors = {};
        Object.keys(formValues).forEach(key => {
            const error = validateField(key, formValues[key]);
            if (error) newErrors[key] = error;
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleBlur = (e) => {
        const { name, value } = e.target;
        const error = validateField(name, value);
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                const response = await fetch('https://andpos.testinguat.com:5435/api/enquiry', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formValues),
                });


                if (response) {
                    const data = await response.json();
                    console.log('Form submitted successfully:', data);
                    if (data.success) {
                        resetForm();
                        toast.success('Form submitted successfully!', {

                        });
                    }
                } else {
                    console.error('Error submitting form:', response.statusText);
                }
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        }
    };

    const products = [
        "Andpos C20 Lite",
        "Andpos C20 SE",
        "Andpos C20 Pro",
        "Andpos Cx20",
        "Andpos M20 SE",
        "Andpos L20",
        "Andpos P20",
        "Andpos P30",
        "Andpos Scanner",
        "Andpos Printer",
        "Andpos Soundbox",
    ];
    return (
        <div>
            {open ?
                <div className='bg-gradient-to-b from-[#FCFAFA] to-[#EBC9C2] w-full grid place-items-center p-4'>
                    <h1 className='text-[#FF5E14] font-bold text-3xl sm:text-4xl lg:text-5xl mt-1 sm:mt-3'>Get in touch with us</h1>
                    <div className='p-4 sm:p-6 lg:p-8 w-2/5'>
                        <p>If you have any inquiries or feedback, we are at your service.Please fill out the form below to contact our dedicated teams, and we'll get back to you as soon as possible.
                            Contact Information</p>
                    </div>
                </div>
                : <div className='bg-gradient-to-b from-[#FCFAFA] to-[#EBC9C2] w-full grid place-items-center p-4'>
                    <h1 className='text-[#FF5E14] font-bold text-3xl sm:text-4xl lg:text-5xl mt-1 sm:mt-3'>Contact Us</h1>
                    <div className='p-4 sm:p-6 lg:p-8'>
                        <img src='/assets/Contact/image 11.png' alt='Contact Us' className='h-32 sm:h-40 lg:h-48 w-auto mt-4 sm:mt-6' />
                    </div>
                </div>
            }
            <div className='grid place-items-center'>
                <h4 className='text-[#FF5E14] mt-10'>Support</h4>
                <h1 className='font-bold text-2xl sm:text-3xl lg:text-4xl'>Want to Discuss</h1>
            </div>

            <div className='w-full p-4 flex flex-col items-center bg-[#FAFDFE]'>
                <form className='w-full max-w-2xl' onSubmit={handleSubmit}>
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
                        <div>
                            <label className='block text-gray-600 text-md font-semibold mb-1'>Merchant Name*</label>
                            <input
                                name='merchant_name'
                                className={`w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF5E14] ${errors.merchant_name ? 'border-red-500' : ''}`}
                                type='text'
                                placeholder='Merchant Name'
                                value={formValues.merchant_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.merchant_name && <p className='text-red-500 text-sm'>{errors.merchant_name}</p>}
                        </div>

                        <div>
                            <label className='block text-gray-600 text-md font-semibold mb-1'>Email*</label>
                            <input
                                name='email'
                                className={`w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF5E14] ${errors.email ? 'border-red-500' : ''}`}
                                type='email'
                                placeholder='Email'
                                value={formValues.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.email && <p className='text-red-500 text-sm'>{errors.email}</p>}
                        </div>

                        <div>
                            <label className='block text-gray-600 text-md font-semibold mb-1'>Mobile Number*</label>
                            <input
                                name='mobile_number'
                                className={`w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF5E14] ${errors.mobile_number ? 'border-red-500' : ''}`}
                                type='text'
                                placeholder='Mobile Number'
                                value={formValues.mobile_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.mobile_number && <p className='text-red-500 text-sm'>{errors.mobile_number}</p>}
                        </div>

                        <div>
                            <label className='block text-gray-600 text-md font-semibold mb-1'>City*</label>
                            <input
                                name='city'
                                className={`w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF5E14] ${errors.city ? 'border-red-500' : ''}`}
                                type='text'
                                placeholder='City'
                                value={formValues.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {errors.city && <p className='text-red-500 text-sm'>{errors.city}</p>}
                        </div>

                        <div>
                            <label className='block text-gray-600 text-md font-semibold mb-1'>Select Product*</label>
                            <select
                                name='product'
                                className={`w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF5E14] ${errors.product ? 'border-red-500' : ''}`}
                                value={formValues.product}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option value=''>Select Product</option>
                                {products.map((product, index) => (
                                    <option key={index} value={product}>
                                        {product}
                                    </option>
                                ))}
                            </select>
                            {errors.product && <p className='text-red-500 text-sm'>{errors.product}</p>}
                        </div>

                        <div>
                            <label className='block text-gray-600 text-md font-semibold mb-1'>Product Quantity*</label>
                            <select
                                name='quantity'
                                className={`w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF5E14] ${errors.quantity ? 'border-red-500' : ''}`}
                                value={formValues.quantity}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option value=''>Select Quantity</option>
                                <option value='100-500'>100-500</option>
                                <option value='500-1000'>500-1000</option>
                                <option value='1000+'>1000+</option>

                            </select>
                            {errors.quantity && <p className='text-red-500 text-sm'>{errors.quantity}</p>}
                        </div>
                    </div>

                    <div className='mt-6'>
                        <label className='block text-gray-600 text-md font-semibold mb-1'>Message</label>
                        <textarea
                            name='message'
                            className='w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#FF5E14]'
                            rows='6'
                            placeholder='Your message...'
                            value={formValues.message}
                            onChange={handleChange}
                        />
                    </div>

                    <div className='mt-6 flex justify-center'>
                        <button
                            type='submit'
                            className='w-20 bg-[#FF5E14] text-white font-bold py-3 px-4 rounded-md hover:bg-[#E54A11]'
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Contact;
