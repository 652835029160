import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Contact from './pages/Contact';
import Header from './Component/Header/Header';
import Footer from './Component/Footer/Footer';
import Home from './pages/home/Home';
import { ToastContainer } from 'react-toastify';
import ProductPage from './pages/Product/ProductPage';
import ScrollToTop from './common/ScrollToTop';

function App() {
  const [loacldata, setLocaldata] = useState(false)

  return (
    <Router>
      <ScrollToTop />
      <ToastContainer />
      <Header isOpen={setLocaldata} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact open={loacldata} />} />
        <Route path="/Products" element={<ProductPage />} />
        {/*  */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
