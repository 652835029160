import React from 'react'
import ProductPageHeader from '../../Component/ProductPageHeader/ProductPageHeader'

function ProductPage() {
  return (
    <div>
      <ProductPageHeader/>
         
    </div>
  )
}

export default ProductPage
