import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const ProductsCard = ({ data }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        dotsClass: "slick-dots custom-dots",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <Slider {...settings}>
            {data && data.map((cardData, index) => (
                <div key={index} className="flex justify-center items-center font-[inter]">
                    <div className="p-6 rounded-lg shadow-lg w-full max-w-xs sm:max-w-sm md:max-w-md min-w-[280px] h-[600px] flex flex-col items-center justify-between border-2 border-[#F3755B]">
                        <img
                            src={cardData.image}
                            alt="Card Image"
                            className="w-[320px] h-[320px]  rounded-lg mb-4"
                        />
                        <h2 className="text-xl font-semibold mb-2 text-center">{cardData.CardHeading}</h2>
                        <p className="text-center mb-4 flex-grow">
                            {cardData.content}
                        </p>
                        <button className="bg-[#FF5E14] text-white py-2 px-4 rounded hover:bg-[#e64a43] focus:outline-none focus:ring-2 focus:ring-[#e64a43]">
                            Know More
                        </button>
                    </div>
                </div>
            ))}
        </Slider>
    );
};

export default ProductsCard;
