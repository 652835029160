import React from "react";

function ProductPageHeader() {
  return (
    <>
      <div className="bg-custom-image">
        <div className="container mx-auto pt-[100px] sm:pt-[150px] lg:pt-[160px] pb-[150px] sm:pb-[180px] lg:pb-[211px] px-4 sm:px-6 lg:px-8">
          <p className="text-[28px] sm:text-[40px] lg:text-[54px] xl:text-[60px] text-[#FF5E14] pb-2 font-semibold leading-10">
            M20 SE <br />
            Mobile Terminal
          </p>
          <p className="text-[20px] sm:text-[30px] lg:text-[40px] xl:text-[40px] leading-10">
            Upgrade your experience with <br />
            speed, convenience,
            <br />
            and portability
          </p>

          <button className="rounded-md bg-[#FF5E14] text-white text-base sm:text-lg lg:text-xl xl:text-2xl py-[10px] sm:py-[12px] lg:py-[10px] px-[20px] sm:px-[25px] xl:px-[10px] mt-12">
            M20 SE DATASHEET
          </button>
        </div>
      </div>
  
  <div
        class="relative bg-cover bg-center w-full xl:py-16 lg:py-16 md:py-4 sm:py-4"
        style={{ backgroundImage: `url('../../../../assets/image.png')` }}
      >
        <div class="absolute inset-0 bg-[#FF5E14] opacity-60"></div>

       <div className="container mx-auto xl:px-0 lg:px-0 md:px-6  sm:px-6 ">
       <div class="flex items-center justify-between xl:py-16 lg:py-16 md:py-4 sm:py-4">
          <div class="text-white z-10 w-6/12">
            <h2 class="text-4xl font-semibold">Faster Processing & Lightweight</h2>
          <div className="max-w-[330px]">
          <p class="mt-4 text-base">
              Equipped with an <span class="font-bold">ARM Quad-core CPU</span>{" "}
              and powered by the latest
              <span class="font-bold">Android 13</span>, M20SE delivers
              lightning-fast processing and seamless performance.
            </p>
          </div>
          </div>

          <div class="relative w-6/12 ">
            <div
              class="w-64 h-64 bg-cover bg-center rounded-full mx-auto"
              
            >
              <img  src="../../../../assets/ProductCompatibility.png" alt="machine img"></img>
            </div>
          </div>
        </div>
       </div>
      </div>
      <div className=" bg-[#EAEAEC]">
<div className="container mx-auto"> 
<div className="flex ">
<div className="w-6/12">
<p className="text-4xl font-semibold">
Hardware Compatibility
</p>
</div>
<div className="w-6/12">

</div>

</div>
</div>
      </div>
 
{/* 
      <div>
        <img
          src="../../../../assets/ProductCompatibility.png"
          alt="fhdfgh"
          srcset=""
          className="ProductCompatibility"
        />
      </div> */}

      <div className="container mx-auto   w-full h-full  lg:py-16 lg:my-5   ">
        <div className="grid sm:grid-cols-2 xs:grid-cols-1 gap-12 flex   ">
          <div className="justify-self-end	mr-20">
            <img
              src="../../../../assets/KeyFeaturePicture.png"
              alt="fhdfgh"
              srcset=""
            />
          </div>
          <div className="justify-items-start self-center">
            <p className="text-4xl font-bold py-5">Key features:</p>
            <ul class="list-disc list-inside space-y-2 font-medium leading-relaxed text-gray-600">
              <li>Powered by Android 13</li>
              <li>Quad-core, 2.0GHz</li>
              <li>6.5" touch screen</li>
              <li>2G/3G/4G, WiFi, GPS, Bluetooth 5.0</li>
            </ul>
            <button className="rounded-md bg-[#FF5E14] text-white text-base sm:text-lg lg:text-md xl:text-lg py-[10px] sm:py-[12px] lg:py-[10px] px-[20px] sm:px-[25px] xl:px-[10px] mt-12">
              M20 SE DATASHEET
            </button>
          </div>
        </div>
      </div>
      <div>
        <img
          src="../../../../assets/AdvanceConnectivity.png"
          alt="fhdfgh"
          srcset=""
          className="ProductCompatibility"
        />
      </div>
      <div className="container mx-auto   w-full h-full  lg:py-16 lg:my-5   ">
        <div className="grid sm:grid-cols-2 xs:grid-cols-1 gap-12 flex   ">
          <div className="justify-self-end	mr-20">
            <img
              src="../../../../assets/Intrupted.png"
              alt="Device"
              class="w-auto h-auto rounded-md"
            />
          </div>
          <div className="justify-items-start self-center w-1/2">
            <div class="relative flex flex-col justify-center text-gray-800">
              <div class="absolute -top-10 left-40 w-32 h-32 bg-gray-400 rounded-full opacity-45"></div>

              <h3 class="font-bold text-xl">Uninterrupted Power</h3>
              <p class="mt-2 text-md font-normal	tracking-wide leading-7 text-black	">
                Stay prepared for any retail task with our Quick-swap battery,
                delivering uninterrupted performance all day long with its
                powerful 7.7V/3500mAH.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductPageHeader;
