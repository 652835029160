import React from "react";

function HeroSection() {
  return (
    <>
      <div className="bg-custom-image">
        <div className="container mx-auto xl:pt-[250px] lg:pt-[160px] pt-[60px]  pb-[60px] sm:pb-[180px] lg:pb-[211px] px-4 sm:px-6 lg:px-8">
          <p className="text-[28px] sm:text-[40px] lg:text-[54px] xl:text-[60px] text-[#FF5E14] pb-2 font-semibold">
            Introducing AndPOS
          </p>
          <p className="text-[20px] sm:text-[30px] lg:text-[40px] xl:text-[40px] leading-10">
            <span className="text-[#FF5E14]">Smart POS</span> Terminal <br />
            Slim, Portable and Fashionable
          </p>
          <p className="text-sm sm:text-base lg:text-lg xl:text-xl text-[#777777] pt-7">
            Go with our Merchant Acquiring Solutions. Let payments <br />{" "}
            technology grow your world.
          </p>
          {/* <button className="rounded-md bg-[#FF5E14] text-white text-base sm:text-lg lg:text-xl xl:text-2xl py-[10px] sm:py-[12px] lg:py-[10px] px-[20px] sm:px-[25px] xl:px-[10px] mt-12">
            Purchase
          </button> */}
        </div>
      </div>
    </>
  );
}

export default HeroSection;
