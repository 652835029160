import React from 'react';

const Card = ({ data }) => {
  return (
    <>
      {data && data.map((cardData, index) => (
        <div
          key={index}
          className="group relative font-[inter] p-10 rounded-lg mx-auto mt-16 border-4 border-transparent transition duration-300 ease-in-out"
        >
          <div className="absolute -top-14 w-24 h-24 bg-[#FFE9DD] z-40 border-4 border-[#FFE1D3] rounded-full flex items-center justify-center transition duration-300 ease-in-out group-hover:border-[#FF5E14]">
            <span className="text-4xl text-[rgb(255,94,20)]">
              {cardData.icon}
            </span>
          </div>
          <h2 className="text-xl font-semibold mb-4 pt-6 text-center">{cardData.CardHeading}</h2>
          <p className="text-gray-700 text-center">
            {cardData.content}
          </p>
          <div className="absolute inset-0 border-4 border-transparent rounded-lg group-hover:border-[#FF5E14] transition duration-300 ease-in-out"></div>
        </div>
      ))}
    </>
  );
}

export default Card;
