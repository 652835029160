import React, { useState } from "react";
import Logo from "../../Images/logo.svg";
import { FiAlignRight } from "react-icons/fi";
import { Link } from "react-router-dom";

const Header = ({ isOpen }) => {
  const [hamberMenu, setHamburgerMenu] = useState(false);
  return (
    <header className="sticky top-0 z-50 shadow-sl bg-white text-[#222222]" style={{
      boxShadow: "0 14px 28px rgba(0,0,0,0.05), 0 10px 10px rgba(0,0,0,0.05)"
    }}>
      <div className="container mx-auto flex justify-between items-center p-4">
        <div className="text-xl font-bold">
          <Link to="/" >
            <img className="xl:w-full lg:w-full" src={Logo} alt="Logo" />
          </Link>
        </div>
        <nav className="hidden md:flex gap-[53px]">
          <Link to="/" >
            <p className=" text-slate-950 font-semibold">Home</p>

          </Link>
          <a href="/#Product" className="text-slate-950 font-semibold">
            Product
          </a>
          <a href="/#about" className=" text-slate-950 font-semibold">
            About
          </a>
          <Link to="/contact" className="text-slate-950 font-semibold" onClick={() => isOpen(false)}>
            Contact Us
          </Link>
          <a href="/#help" className=" text-slate-950 font-semibold">
            Help
          </a>
        </nav>
        <Link to="/contact">

          <button className="hidden md:block rounded-md bg-[#FF5E14] text-white text-base sm:text-lg lg:text-xl py-1 sm:py-[7px] px-3 sm:px-[15px]" onClick={() => isOpen(true)} >
            Get In Touch
          </button>
        </Link>

        <div className="md:hidden">
          <button className="text-white bg-gray-800 focus:outline-none p-2 rounded" onClick={() => setHamburgerMenu(!hamberMenu)}>
            <FiAlignRight size={24} />
          </button>
        </div>
      </div>

      {hamberMenu && (
        <div className="md:hidden">
          <nav className="flex flex-col items-center space-y-2 p-4">
            <a href="/" className="hover:text-gray-400">
              Home
            </a>
            <a href="/Products" className="hover:text-gray-400">
              Product
            </a>
            <a href="/#about" className="hover:text-gray-400">
              About
            </a>
            <a href="/contact" className="hover:text-gray-400">
              Contact Us
            </a>
            <a href="/#help" className="hover:text-gray-400">
              Help
            </a>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
