import React from "react";

const AboutUs = () => {
  return (
    <>
      <div className="container mx-auto lg:px-10  w-full h-full  lg:py-16 lg:my-5" id="about"> 
      <section className="w-4/5 mx-auto font-[inter] pt-24">
        <h3 className="text-[42px] md:text-[48px] font-semibold block text-center">
          About Us
        </h3>
        <p className="text-center text-base md:text-lg mt-4 font-thin text-gray-500 ">
          Point of Sale (POS) systems are versatile and can offer a variety of
          services depending on the business type and industry.
          <br /> Here are some common types of POS services:
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 pt-10 md:py-20">
          <div className="px-5">
            <h4 className="text-[20px] md:text-[25px] font-semibold">
              The Widest Portfolio of POS Terminals
            </h4>
            <p className="pt-4 text-sm md:text-base">
              A venture by Mantra Softech, Andpos carries forward the legacy of
              quality and trust. Through Andpos, Mantra enters into the
              Point-of-Sale (POS) terminals market in India. Andpos brings
              best-in-class POS devices from premium brands on a single platform
              to enhance the digital and physical customer experience. The
              sophisticated and smart payment solutions help businesses in
              connecting efficiently with their consumers anytime, anywhere, and
              streamline their payment operations.
            </p>
          </div>
          <div className="flex justify-center items-center">
            <img
              src="./images/AboutUs.png"
              alt="About Us"
              className="w-full h-auto max-w-xs md:max-w-sm"
            />
          </div>
        </div>
      </section>

      </div>
    </>
  );
};

export default AboutUs;
